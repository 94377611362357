<template>
  <div class="policy-list clearfix">
    <div class="news-column">
      <div class="column-title">
        政策法规
      </div>
      <div class="children-list">
        <div v-if="records > 0"
             v-for="(tree, index) in threeData"
             :key="tree.id"
             class="item"
             @click="changeTree(tree, index)"
             :class="[threeActive === index ? 'list-active' : '']">
          {{tree.name}}
        </div>
        <xgj-empty v-if="records === 0" title="暂无子栏目" height="310px"></xgj-empty>
      </div>
    </div>
    <div class="news-list">
      <div class="headline-box">
        <div class="title-lef">
          <div class="title-cn">政策法规</div>
          <div class="title-en">POLICIES REGULATIONS</div>
        </div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>当前位置：</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{currentTitle}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="list-box" v-if="listView">
        <div class="list-item"
             v-if="records > 0"
             v-for="(list, index) in listData"
             :key="list.id">
          <div class="item-img">
            <img v-if="list.image" class="item-card-img-lef" :src="list.image.url" />
            <img v-else class="item-card-img-lef" src="../../assets/images/news/group-list-avter.png" />
          </div>
          <article class="list-article">
            <div class="article-title" @click="getDetail(list.id)">{{list.title}}</div>
            <div class="article-content mui-ellipsis-3 ie-mui-ellipsis-3">
              {{list.content}}
            </div>
            <div class="article-date">{{list.publish_time}}</div>
          </article>
        </div>
        <xgj-empty v-if="records === 0" title="新闻资讯暂无数据" height="310px"></xgj-empty>
        <div v-if="records > 0" class="web-pages">
          <el-pagination
            background
            @size-change="sizeDetailChange"
            @current-change="currentDetailChange"
            :current-page.sync="detail_paging.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="detail_paging.size"
            :total="detail_paging.total_elements"
            layout="total, sizes, prev, pager, next, jumper">
          </el-pagination>
        </div>
      </div>
      <article v-if="!listView && details" class="list-detail">
        <h3 class="detail-title">{{details.title}}</h3>
        <div class="detail-date">{{details.publish_time}}</div>
        <div class="detail-border"></div>
        <div class="detail-article">
          <div class="a-con" v-html="details.content"></div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import util from "../../utils/util";
import _ from 'lodash'
export default {
  name: 'news-list',
  data() {
    return {
      detail_paging: {  //分页
        page: 1,
        size: 10,
        total_elements: 0
      },
      listData: [],
      threeData: [],
      threeActive: '',
      threeStatus: false,
      currentTitle: '政策法规',
      columnId: '92fac6f56121425fb1b82400f6ac7999',
      listView: true,
      details: null,
      records: ''
    }
  },
  watch: {
    threeData() {
      this.$nextTick(() => {
        if (this.$route.query.type) {
          this.changeTree(this.threeData[this.$route.query.type], Number(this.$route.query.type))
        }
        if (!_.isEmpty(this.$route.params)) {
          this.changeTree(this.threeData[this.$route.params.type], Number(this.$route.params.type));
          this.listView = false;
          this.getDetail(this.$route.params.id)
        }
      })
    }
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: '加载中',
      background: 'rgba(255, 255, 255, .8)'
    });
    Promise.all([this.getContentColumn()])
      .then(() => {
        loading.close()
      }).catch(reason => {
        loading.close()
      })
    if (!_.isEmpty(this.$route.query) && this.$route.query.listView) {
      this.listView = false;
      this.getDetail(this.$route.query.id)
    }
  },
  methods: {
    changeTree(data, index) {
      this.listView = true;
      this.threeActive = index;
      this.currentTitle = data.name;
      this.columnId = data.id;
      this.detail_paging.page = 1;
      this.detail_paging.size = 10;
      this.detail_paging.total_elements = 0;
      this.getContentColumn()
    },
    //改变每页数量
    sizeDetailChange(size) {
      this.detail_paging.size = size;
      this.getContentColumn()
    },
    //改变页数
    currentDetailChange(page) {
      this.detail_paging.page = page;
      this.getContentColumn()
    },
    getContentColumn() {
      const that = this;
      return new Promise((resolve,reject) => {
        let params = {
          partnerId: that.$store.state.user.partnerId,
          columnId: that.columnId,
          page: that.detail_paging.page,
          rows: that.detail_paging.size
        };

        that.$api.website.getContentColumn(params)
          .then( dates => {
            window.scrollTo(0,0);
            that.listData = [];
            dates.website_column.children.reverse()
            if (!that.threeStatus) {
              if (dates.website_column.children.length > 0) {
                dates.website_column.children.forEach( data => {
                  that.threeData.push(data)
                })
              }
            }
            let fmt = 'yyyy年MM月dd日';
            dates.website_content_list.forEach( data => {
              data.publish_time = util.formatDate( data.publish_time, fmt);
              that.listData.push(data)
            });
            that.detail_paging.size = dates.paging.page_size;
            that.detail_paging.page = dates.paging.current_page;
            that.detail_paging.total_elements = dates.paging.records;
            that.threeStatus = true;
            that.records = dates.paging.records || that.listData.length;
            resolve()
          }).catch( err => {
            that.records = 0;
            reject(err)
          })
      })
    },
    getDetail(id) {
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        background: 'rgba(255, 255, 255, .8)'
      });
      this.$api.website.getContentDetail({id: id})
        .then( detail => {
          this.details = detail;
          this.listView = false;
          loading.close()
        }).catch( err => {
          loading.close()
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  /deep/ .el-breadcrumb {
    margin-right: 48px;
    position: absolute;
    right: 0;
    top: 26px;
    .el-breadcrumb__inner {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
  }
  @import "./policy.scss";
</style>
